var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
import { createRef } from "react";
// Customizable Area End
export const configJSON = require("./config");
export default class EditTeamMemberWebController extends BlockComponent {
    // Customizable Area End
    constructor(props) {
        super(props);
        // Customizable Area Start
        this.selectContainerEdit = createRef();
        this.handleClickOutsideEdit = (event) => {
            if (this.selectContainerEdit.current && !this.selectContainerEdit.current.contains(event.target)) {
                this.setState({
                    isSelectOpenWeekHoursTo: false,
                    isSelectOpenWeekHoursFrom: false,
                    isSelectOpenWeekEndsTo: false,
                    isSelectOpenWeekEndsFrom: false,
                    isSelectOpenBlockWeekHoursTo: false,
                    isSelectOpenBlockWeekHoursFrom: false,
                    isSelectOpenBlockWeekEndsTo: false,
                    isSelectOpenBlockWeekEndsFrom: false,
                });
            }
        };
        this.handleReceive = (apiRequestCallId, responseJson, errorJson) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
            if (apiRequestCallId === this.getTeamMemberDataCallId) {
                const memberData = responseJson.data.attributes;
                this.setState({
                    empData: responseJson.data.attributes,
                    capturedImage: (memberData === null || memberData === void 0 ? void 0 : memberData.team_member_profile) ? [{ dataURL: memberData === null || memberData === void 0 ? void 0 : memberData.team_member_profile }]
                        : "",
                });
                if (responseJson.data.length === 0) {
                    this.setState({
                        errorMsg: "Data Not Found",
                        loading: false,
                    });
                }
                else {
                    if (((_a = memberData === null || memberData === void 0 ? void 0 : memberData.working_days) === null || _a === void 0 ? void 0 : _a.length) !== 0) {
                        let working_days = memberData === null || memberData === void 0 ? void 0 : memberData.working_days.map(function (x) {
                            return x.toUpperCase();
                        });
                        let updateWorkday = (_b = this.state.workingDaysData) === null || _b === void 0 ? void 0 : _b.map((ele, index) => {
                            if (working_days === null || working_days === void 0 ? void 0 : working_days.includes(ele.day.toUpperCase())) {
                                let temp = Object.assign({}, ele);
                                temp.isWeekday = true;
                                temp.isDisableWeekend = true;
                                return temp;
                            }
                            else {
                                return ele;
                            }
                        });
                        this.setState({
                            workingDaysData: updateWorkday,
                            workingDays: memberData === null || memberData === void 0 ? void 0 : memberData.working_days,
                        });
                    }
                    if (((_c = memberData === null || memberData === void 0 ? void 0 : memberData.weekend_working_days) === null || _c === void 0 ? void 0 : _c.length) !== 0) {
                        let weekends = memberData === null || memberData === void 0 ? void 0 : memberData.weekend_working_days.map(function (x) {
                            return x.toUpperCase();
                        });
                        let updateWorkday = (_d = this.state.workingDaysData) === null || _d === void 0 ? void 0 : _d.map((ele, index) => {
                            if (weekends === null || weekends === void 0 ? void 0 : weekends.includes(ele.day.toUpperCase())) {
                                let temp = Object.assign({}, ele);
                                temp.isWeekend = true;
                                temp.isDisableWeekday = true;
                                return temp;
                            }
                            else {
                                return ele;
                            }
                        });
                        this.setState({
                            workingDaysData: updateWorkday,
                            weekendDays: memberData === null || memberData === void 0 ? void 0 : memberData.weekend_working_days,
                        });
                    }
                    this.setState({
                        profileImage: (memberData === null || memberData === void 0 ? void 0 : memberData.team_member_profile) ? [{ dataURL: memberData === null || memberData === void 0 ? void 0 : memberData.team_member_profile }]
                            : "",
                        Name: memberData.title,
                        Fname: memberData.first_name,
                        Lname: memberData.last_name,
                        Email: memberData.email_address,
                        selectedCountryCode: memberData.country_code.includes("+")
                            ? memberData.country_code
                            : `+${memberData.country_code}`,
                        Mobile: memberData.mobile_number,
                        Gender: memberData.gender,
                        Dob: memberData.date_of_birth,
                        Language: memberData.prefered_language,
                        AboutMe: memberData.about_me,
                        StartDate: memberData.emp_start_date,
                        EndDate: memberData.emp_end_date,
                        workingDays: memberData.working_days,
                        weekendDays: memberData.weekend_working_days,
                        workingFrom: this.formatTime((_e = memberData.working_time_from) === null || _e === void 0 ? void 0 : _e.toUpperCase()),
                        workingTo: this.formatTime((_f = memberData.working_time_to) === null || _f === void 0 ? void 0 : _f.toUpperCase()),
                        BlockTimeFrom: this.formatTime((_g = memberData === null || memberData === void 0 ? void 0 : memberData.block_time_from) === null || _g === void 0 ? void 0 : _g.toUpperCase()),
                        BlockTimeTo: this.formatTime((_h = memberData === null || memberData === void 0 ? void 0 : memberData.block_time_to) === null || _h === void 0 ? void 0 : _h.toUpperCase()),
                        weekendFrom: this.formatTime((_j = memberData === null || memberData === void 0 ? void 0 : memberData.weekend_time_from) === null || _j === void 0 ? void 0 : _j.toUpperCase()),
                        weekendTo: this.formatTime((_k = memberData === null || memberData === void 0 ? void 0 : memberData.weekend_time_to) === null || _k === void 0 ? void 0 : _k.toUpperCase()),
                        weekendBlockFrom: this.formatTime((_l = memberData === null || memberData === void 0 ? void 0 : memberData.weekend_block_time_from) === null || _l === void 0 ? void 0 : _l.toUpperCase()),
                        weekendBlockTo: this.formatTime((_m = memberData === null || memberData === void 0 ? void 0 : memberData.weekend_block_time_to) === null || _m === void 0 ? void 0 : _m.toUpperCase()),
                        Services: memberData.team_member_services,
                    }, () => {
                        this.onWorkingFromSelection(this.state.workingFrom);
                        this.onWeekendFromSelection(this.state.weekendFrom);
                        this.onBlockTimeFromSelection(this.state.BlockTimeFrom);
                        this.onBlockWeekEndTimeFromSelection(this.state.weekendBlockFrom);
                    });
                }
            }
            if (apiRequestCallId === this.updateTeamMemberCallId) {
                this.handleTeamMember(responseJson, errorJson);
            }
            if (apiRequestCallId === this.deleteTeamMemberCallId) {
                this.deleteTeamMember(responseJson, errorJson);
            }
        };
        this.getTeamMembers = () => {
            const header = {
                "Content-Type": configJSON.validationApiContentType,
                token: localStorage.getItem("token") || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getTeamMemberDataCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `account_block/team_members/${this.state.searchId}`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getApiMethod);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.handleTeamMember = (responseJson, errorJson) => {
            if (responseJson === null || responseJson === void 0 ? void 0 : responseJson.data) {
                toast.success(this.props.t("Team Member Details Edited Successfully"), {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    style: {
                        direction: "inherit"
                    }
                });
                this.props.handleAddTeamMember("none", null);
            }
            if (responseJson === null || responseJson === void 0 ? void 0 : responseJson.errors) {
                toast.error(this.props.t(responseJson === null || responseJson === void 0 ? void 0 : responseJson.errors[0].message.trim()), {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    style: {
                        direction: "inherit",
                    }
                });
            }
        };
        //istanbul ignore next
        this.deleteTeamMember = (responseJson, errorJson) => {
            toast.success(this.props.t("Team Member Deleted Successfully"), {
                position: toast.POSITION.BOTTOM_RIGHT,
                style: {
                    direction: "inherit",
                },
            });
            this.props.handleAddTeamMember("none", null);
        };
        this.startLoading = () => {
            this.setState({ loading: true });
        };
        this.stopLoading = () => {
            this.setState({ loading: false });
        };
        this.getServicesListApi = () => {
            const header = {
                "Content-Type": configJSON.validationApiContentType,
                token: localStorage.getItem("token") || "",
            };
            const getSServicesApiequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getServicesListCallId = getSServicesApiequestMessage.messageId;
            getSServicesApiequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getServicesListEndpoint);
            getSServicesApiequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            getSServicesApiequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getApiMethod);
            runEngine.sendMessage(getSServicesApiequestMessage.id, getSServicesApiequestMessage);
            return true;
        };
        this.handleServiceResponse = (responseJson) => {
            this.setState({
                serviceList: responseJson.data,
                originalServiceList: responseJson.data,
            }, () => {
                this.reapplySelectedStateEdit();
            });
        };
        //istanbul ignore next
        this.handleUpdateTeamMember = () => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
            if (((_a = this.state.profileImage[0]) === null || _a === void 0 ? void 0 : _a.dataURL) == ((_b = this.state.capturedImage[0]) === null || _b === void 0 ? void 0 : _b.dataURL) &&
                this.state.Name == this.state.empData.title &&
                this.state.Fname == this.state.empData.first_name &&
                this.state.Lname == this.state.empData.last_name &&
                this.state.Email == this.state.empData.email_address &&
                this.state.selectedCountryCode == this.state.empData.country_code &&
                this.state.Mobile == this.state.empData.mobile_number &&
                this.state.Gender == this.state.empData.gender &&
                this.state.Dob == this.state.empData.date_of_birth &&
                this.state.Language == this.state.empData.prefered_language &&
                this.state.AboutMe == this.state.empData.about_me &&
                this.state.StartDate == this.state.empData.emp_start_date &&
                this.state.EndDate == this.state.empData.emp_end_date &&
                this.state.workingDays == this.state.empData.working_days &&
                this.state.weekendDays == this.state.empData.weekend_working_days &&
                this.state.workingFrom == ((_c = this.state.empData.working_time_from) === null || _c === void 0 ? void 0 : _c.toUpperCase()) &&
                this.state.workingTo == ((_d = this.state.empData.working_time_to) === null || _d === void 0 ? void 0 : _d.toUpperCase()) &&
                this.state.BlockTimeFrom == ((_f = (_e = this.state.empData) === null || _e === void 0 ? void 0 : _e.block_time_from) === null || _f === void 0 ? void 0 : _f.toUpperCase()) &&
                this.state.BlockTimeTo == ((_h = (_g = this.state.empData) === null || _g === void 0 ? void 0 : _g.block_time_to) === null || _h === void 0 ? void 0 : _h.toUpperCase()) &&
                this.state.weekendFrom == ((_k = (_j = this.state.empData) === null || _j === void 0 ? void 0 : _j.weekend_time_from) === null || _k === void 0 ? void 0 : _k.toUpperCase()) &&
                this.state.weekendTo == ((_m = (_l = this.state.empData) === null || _l === void 0 ? void 0 : _l.weekend_time_to) === null || _m === void 0 ? void 0 : _m.toUpperCase()) &&
                this.state.weekendBlockFrom == ((_p = (_o = this.state.empData) === null || _o === void 0 ? void 0 : _o.weekend_block_time_from) === null || _p === void 0 ? void 0 : _p.toUpperCase()) &&
                this.state.weekendBlockTo == ((_r = (_q = this.state.empData) === null || _q === void 0 ? void 0 : _q.weekend_block_time_to) === null || _r === void 0 ? void 0 : _r.toUpperCase()) &&
                this.state.Services == this.state.empData.team_member_services) {
                toast.error(this.props.t("Nothing has been updated to edit"), {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    style: {
                        direction: "inherit"
                    },
                });
            }
            else {
                this.updateTeamMemberAPICall(this.state);
            }
        };
        this.updateTeamMemberAPICall = (state) => {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            const header = {
                "Content-Type": configJSON.validationApiContentType,
                token: localStorage.getItem("token") || "",
            };
            const stateKeys = {
                team_member_profile: (_a = state.profileImage[0]) === null || _a === void 0 ? void 0 : _a.dataURL,
                title: state.Name,
                first_name: state.Fname,
                last_name: state.Lname,
                email_address: state.Email,
                country_code: state.selectedCountryCode,
                mobile_number: state.Mobile,
                gender: state.Gender,
                date_of_birth: state.Dob,
                prefered_language: state.Language,
                about_me: state.AboutMe,
                emp_start_date: state.StartDate,
                emp_end_date: state.EndDate,
                working_days: state.workingDays,
                weekend_working_days: state.weekendDays,
                working_time_from: state.workingFrom.toUpperCase(),
                working_time_to: state.workingTo.toUpperCase(),
                block_time_from: state.BlockTimeFrom.toUpperCase(),
                block_time_to: state.BlockTimeTo.toUpperCase(),
                weekend_time_from: state.weekendFrom.toUpperCase(),
                weekend_time_to: state.weekendTo.toUpperCase(),
                weekend_block_time_from: state.weekendBlockFrom.toUpperCase(),
                weekend_block_time_to: state.weekendBlockTo.toUpperCase(),
                business_sub_categories_ids: state.Services,
            };
            const empDataKeys = {
                team_member_profile: (_b = state.capturedImage[0]) === null || _b === void 0 ? void 0 : _b.dataURL,
                title: state.empData.title,
                first_name: state.empData.first_name,
                last_name: state.empData.last_name,
                email_address: state.empData.email_address,
                country_code: state.empData.country_code,
                mobile_number: state.empData.mobile_number,
                gender: state.empData.gender,
                date_of_birth: state.empData.date_of_birth,
                prefered_language: state.empData.prefered_language,
                about_me: state.empData.about_me,
                emp_start_date: state.empData.emp_start_date,
                emp_end_date: state.empData.emp_end_date,
                working_days: state.empData.working_days,
                weekend_working_days: state.empData.weekend_working_days,
                working_time_from: state.empData.working_time_from.toUpperCase(),
                working_time_to: state.empData.working_time_to.toUpperCase(),
                block_time_from: (_c = state.empData) === null || _c === void 0 ? void 0 : _c.block_time_from.toUpperCase(),
                block_time_to: (_d = state.empData) === null || _d === void 0 ? void 0 : _d.block_time_to.toUpperCase(),
                weekend_time_from: (_e = state.empData) === null || _e === void 0 ? void 0 : _e.weekend_time_from.toUpperCase(),
                weekend_time_to: (_f = state.empData) === null || _f === void 0 ? void 0 : _f.weekend_time_to.toUpperCase(),
                weekend_block_time_from: (_g = state.empData) === null || _g === void 0 ? void 0 : _g.weekend_block_time_from.toUpperCase(),
                weekend_block_time_to: (_h = state.empData) === null || _h === void 0 ? void 0 : _h.weekend_block_time_to.toUpperCase(),
                business_sub_categories_ids: state.empData.team_member_services,
            };
            const changedData = Object.keys(stateKeys).reduce((acc, key) => {
                const stateValue = stateKeys[key];
                const empDataValue = empDataKeys[key];
                if (key === "team_member_profile") {
                    if (stateValue !== empDataValue) {
                        acc[key] = { data: stateValue };
                    }
                }
                else {
                    if (stateValue !== empDataValue) {
                        acc[key] = stateValue;
                    }
                }
                return acc;
            }, {});
            runEngine.debugLog(changedData, "changedData");
            const httpBody = {
                data: Object.assign({}, changedData),
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.updateTeamMemberCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `account_block/team_members/${this.state.searchId}`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.putApiMethod);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.handleDeleteTeamMember = () => {
            const header = {
                "Content-Type": configJSON.validationApiContentType,
                token: localStorage.getItem("token") || "",
            };
            const deleteTeamMemberRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.deleteTeamMemberCallId = deleteTeamMemberRequestMessage.messageId;
            deleteTeamMemberRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `account_block/team_members/${this.state.searchId}`);
            deleteTeamMemberRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            deleteTeamMemberRequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.deleteApiMethod);
            runEngine.sendMessage(deleteTeamMemberRequestMessage.id, deleteTeamMemberRequestMessage);
            return true;
        };
        this.onInputChange = (event, service) => {
            let newServices = [];
            this.state.Services.forEach((serv) => {
                if (Array.isArray(serv)) {
                    serv.forEach((servi) => {
                        if (!newServices.includes(servi.id.toString())) {
                            newServices.push(servi.id.toString());
                        }
                    });
                }
                else {
                    if (!newServices.includes(serv === null || serv === void 0 ? void 0 : serv.toString())) {
                        newServices.push(serv === null || serv === void 0 ? void 0 : serv.toString());
                    }
                }
            });
            if (event.target.checked) {
                this.setState({ Services: [...newServices, event.target.value] }, () => this.getCategoryIdIfAllSubCategoriesMatchEdit(service));
            }
            else {
                const removeService = newServices.filter((service) => service !== event.target.value);
                this.setState({ Services: removeService }, () => this.getCategoryIdIfAllSubCategoriesMatchEdit(service));
            }
        };
        this.onImageChange = (imageList, addUpdateIndex) => {
            // data for submit
            if (imageList.length === 1) {
                this.setState({ profileImage: imageList, imageChange: true });
            }
            else {
                this.setState({ profileImage: [] });
            }
        };
        this.validateName = () => {
            if (this.state.Name === "") {
                this.setState({ NameError: "Please enter title" });
            }
            else {
                this.setState({ NameError: "" });
            }
        };
        this.validateFname = () => {
            if (this.state.Fname === "") {
                this.setState({ FnameError: "Please enter first name" });
            }
            else {
                this.setState({ FnameError: "" });
            }
        };
        this.validateLname = () => {
            if (this.state.Lname === "") {
                this.setState({ LnameError: "Please enter last name" });
            }
            else {
                this.setState({ LnameError: "" });
            }
        };
        this.validateEmail = () => {
            const EmailRegExr = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if (this.state.Email === "") {
                this.setState({ EmailError: "Please enter email" });
            }
            else if (!this.state.Email.match(EmailRegExr)) {
                this.setState({ EmailError: "Invalid email" });
            }
            else {
                this.setState({ EmailError: "" });
            }
        };
        this.validateMobile = () => {
            var _a, _b;
            const contactRegex = /^[0-9]{8,14}$/;
            if (this.state.Mobile === "") {
                this.setState({ MobileError: "Please add your contact number" });
            }
            else if (!((_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.Mobile) === null || _b === void 0 ? void 0 : _b.match(contactRegex))) {
                this.setState({ MobileError: "Please add your contact number" });
            }
            else {
                this.setState({ MobileError: "" });
            }
        };
        this.renderErrorMessageforProfile = (Message) => {
            const errorMessage = Message ? Message : null;
            return errorMessage;
        };
        this.getBackGroundColor = (selectionMode, weekType) => {
            return selectionMode === weekType ? "#1e5fea" : undefined;
        };
        this.getTextColor = (selectionMode, weekType) => {
            return selectionMode === weekType ? "#FFFFFF" : "#2C2C2E";
        };
        this.getBorderStyle = (error) => {
            return error ? "1px solid #FF453A" : "1px solid #757575";
        };
        this.getTime = (timeType) => {
            const { getSelectionMode, weekdayFrom, WeekdayTo, weekendFrom, weekendTo, weekdayBlockFrom, weekdayBlockTo, weekendBlockFrom, weekendBlockTo, } = this.state;
            const timeMap = {
                from: getSelectionMode === "weekdays" ? weekdayFrom : weekendFrom,
                to: getSelectionMode === "weekdays" ? WeekdayTo : weekendTo,
                blockFrom: getSelectionMode === "weekdays" ? weekdayBlockFrom : weekendBlockFrom,
                blockTo: getSelectionMode === "weekdays" ? weekdayBlockTo : weekendBlockTo,
            };
            return timeMap[timeType];
        };
        this.onWeekendFromSelection = (selectedItem) => {
            if (this.state.weekendFrom !== selectedItem) {
                this.setState({
                    weekendTo: "",
                });
            }
            this.setState({
                isWeekendToDisable: false,
                weekendFrom: selectedItem,
                weekendHoursError: "",
            });
            let weekendFrom;
            this.state.workingSlot.forEach((item, weekendFromIndex) => {
                if (item === selectedItem) {
                    weekendFrom = weekendFromIndex;
                    let lastIndex = this.state.workingSlot.length - 1;
                    let fromIndex = this.state.workingSlot.filter((item, weekendFromIndex) => {
                        if (weekendFromIndex > weekendFrom) {
                            return item;
                        }
                    });
                    fromIndex.unshift("");
                    this.setState({ weekendSlotTo: fromIndex });
                    if (lastIndex === weekendFrom) {
                        let toTime = this.state.workingSlot.filter((item, weekendFromIndex) => {
                            if (weekendFromIndex !== weekendFrom) {
                                return item;
                            }
                        });
                        toTime.unshift("");
                        this.setState({ weekendSlotTo: toTime });
                    }
                }
            });
        };
        this.onWorkingFromSelection = (selectedItem) => {
            if (this.state.workingFrom !== selectedItem) {
                this.setState({
                    workingTo: "",
                });
            }
            this.setState({
                isWorkingToDisable: false,
                workingFrom: selectedItem,
                workingHoursError: "",
            });
            let inds;
            this.state.workingSlot.forEach((item, fromindex) => {
                if (item === selectedItem) {
                    inds = fromindex;
                    let lastIndex = this.state.workingSlot.length - 1;
                    let fromIndex = this.state.workingSlot.filter((item, indexfrom) => {
                        if (indexfrom > inds) {
                            return item;
                        }
                    });
                    fromIndex.unshift("");
                    this.setState({ workingSlotTo: fromIndex });
                    if (lastIndex === inds) {
                        let toTime = this.state.workingSlot.filter((item, indexfrom) => {
                            if (indexfrom !== inds) {
                                return item;
                            }
                        });
                        toTime.unshift("");
                        this.setState({ workingSlotTo: toTime });
                    }
                }
            });
        };
        this.onBlockTimeFromSelection = (selectedItem) => {
            if (this.state.BlockTimeFrom !== selectedItem) {
                this.setState({
                    BlockTimeTo: "",
                });
            }
            this.setState({
                isWorkingToDisable: false,
                BlockTimeFrom: selectedItem,
                workingHoursError: "",
            });
            let fromWeekdaysBlock;
            this.state.workingSlot.forEach((item, fromWeekdaysBlockIndex) => {
                if (item === selectedItem) {
                    fromWeekdaysBlock = fromWeekdaysBlockIndex;
                    let lastIndex = this.state.workingSlot.length - 1;
                    let fromIndex = this.state.workingSlot.filter((item, fromWeekdaysBlockIndex) => {
                        if (fromWeekdaysBlockIndex > fromWeekdaysBlock) {
                            return item;
                        }
                    });
                    fromIndex.unshift("");
                    this.setState({ blockWorkingSlotTo: fromIndex });
                    if (lastIndex === fromWeekdaysBlock) {
                        let toTime = this.state.workingSlot.filter((item, fromWeekdaysBlockIndex) => {
                            if (fromWeekdaysBlockIndex !== fromWeekdaysBlock) {
                                return item;
                            }
                        });
                        toTime.unshift("");
                        this.setState({ blockWorkingSlotTo: toTime });
                    }
                }
            });
        };
        this.onBlockWeekEndTimeFromSelection = (selectedItem) => {
            if (this.state.weekendBlockFrom !== selectedItem) {
                this.setState({
                    weekendBlockTo: "",
                });
            }
            this.setState({
                isWeekendToDisable: false,
                weekendBlockFrom: selectedItem,
                workingHoursError: "",
            });
            let weekendBlockFrom;
            this.state.workingSlot.forEach((item, weekendBlockFromIndex) => {
                if (item === selectedItem) {
                    weekendBlockFrom = weekendBlockFromIndex;
                    let lastIndex = this.state.workingSlot.length - 1;
                    let fromIndex = this.state.workingSlot.filter((item, weekendBlockFromIndex) => {
                        if (weekendBlockFromIndex > weekendBlockFrom) {
                            return item;
                        }
                    });
                    fromIndex.unshift("");
                    this.setState({ blockWeekendSlotTo: fromIndex });
                    if (lastIndex === weekendBlockFrom) {
                        let toTime = this.state.workingSlot.filter((item, weekendBlockFromIndex) => {
                            if (weekendBlockFromIndex !== weekendBlockFrom) {
                                return item;
                            }
                        });
                        toTime.unshift("");
                        this.setState({ blockWeekendSlotTo: toTime });
                    }
                }
            });
        };
        this.onDateChange = (value, type) => {
            const formattedDate = moment(value).format("YYYY-MM-DD");
            switch (type) {
                case "start":
                    this.setState({
                        StartDate: formattedDate,
                        StartDateError: value ? "" : "Please choose your date",
                    });
                    break;
                case "end":
                    if (value == "ongoing") {
                        this.setState({
                            EndDate: value,
                            EndDateError: value ? "" : "Please choose your date",
                        });
                    }
                    else {
                        this.setState({
                            EndDate: formattedDate,
                            EndDateError: value ? "" : "Please choose your date",
                        });
                    }
                    break;
                default:
                    break;
            }
        };
        this.getDate = () => {
            if (this.state.DateModalType === "dateOfBirth") {
                return this.state.Dob;
            }
            if (this.state.DateModalType === "startDate") {
                return this.state.StartDate;
            }
            else {
                return this.state.EndDate;
            }
        };
        this.handleSelectToggle = (key) => {
            this.setState((prevState) => {
                const newStateEdit = Object.assign({}, prevState);
                newStateEdit[key] = !prevState[key];
                Object.keys(newStateEdit).forEach((stateKey) => {
                    if (stateKey !== key && stateKey.startsWith('isSelectOpen')) {
                        newStateEdit[stateKey] = false;
                    }
                });
                return newStateEdit;
            });
        };
        this.optionDisable = (value) => {
            return value === "" ? true : false;
        };
        this.showTimeInUppercase = (value) => {
            return value.toUpperCase();
        };
        this.handleBackgroundColor = (item) => {
            const isWeekendBgColor = item.isWeekend;
            const isWeekdayBgColor = item.isWeekday;
            let backgroundColor = "#f9f9f9";
            const selectionMode = this.state.getSelectionMode;
            if ((selectionMode === "weekends" && isWeekendBgColor) ||
                (selectionMode === "weekdays" && isWeekdayBgColor)) {
                backgroundColor = "#1E5FEA";
            }
            return backgroundColor;
        };
        this.handleColor = (item) => {
            const isWeekendColor = item.isWeekend;
            const isWeekdayColor = item.isWeekday;
            let color = "#808080";
            const selectionMode = this.state.getSelectionMode;
            if (selectionMode === "weekdays" && isWeekdayColor) {
                color = "#FFFFFF";
            }
            else if (selectionMode === "weekends" && isWeekendColor) {
                color = "#FFFFFF";
            }
            return color;
        };
        this.handleWorkingHoursSelection = (itemId) => {
            const { getSelectionMode, workingDaysError, weekendDaysError } = this.state;
            const updatedWorkingDaysData = this.state.workingDaysData.map((item) => {
                if (itemId !== item.id)
                    return item;
                if (getSelectionMode === "weekdays" && !item.isWeekend) {
                    item.isWeekday = !item.isWeekday;
                    item.isDisableWeekend = item.isWeekday;
                }
                else if (getSelectionMode === "weekends" && !item.isWeekday) {
                    item.isWeekend = !item.isWeekend;
                    item.isDisableWeekday = item.isWeekend;
                }
                return item;
            });
            const workingDays = updatedWorkingDaysData
                .filter((item) => item.isWeekday)
                .map((item) => item.day);
            const weekendDays = updatedWorkingDaysData
                .filter((item) => item.isWeekend)
                .map((item) => item.day);
            this.setState({
                workingDaysData: updatedWorkingDaysData,
                workingDays: workingDays,
                workingDaysError: workingDays.length ? "" : workingDaysError,
                weekendDays: weekendDays,
                weekendDaysError: weekendDays.length ? "" : weekendDaysError,
            });
        };
        this.updatedSwitchData = (val) => {
            this.setState({ getSelectionMode: val });
        };
        this.handleCountryCodeChange = (value) => {
            if (value) {
                this.setState({
                    openCountryCodeModal: false,
                    selectedCountryCode: value,
                });
            }
            else {
                this.setState({ openCountryCodeModal: !this.state.openCountryCodeModal });
            }
        };
        this.selectCall = (item) => {
            if (this.state.providedService.includes(item.id)) {
                let selectedServices = [...this.state.Services];
                let removeService = [];
                let newArr = [];
                this.state.serviceList.forEach((service) => {
                    service.attributes.business_sub_categories.forEach((serviceItem) => {
                        if (serviceItem.category_id == item.id) {
                            let selectCheckbox = document.querySelector("#serviceCheckbox" + serviceItem.id);
                            if (selectCheckbox && selectCheckbox.checked) {
                                selectCheckbox.click();
                                newArr.push(serviceItem.id);
                            }
                        }
                    });
                });
                for (let a of selectedServices) {
                    let flag = false;
                    for (let b of newArr) {
                        if (a == b) {
                            flag = true;
                        }
                    }
                    if (flag == false) {
                        removeService.push(a);
                    }
                }
                let newProvidedService = [];
                newProvidedService = this.state.providedService.filter((provided) => provided != item.id);
                this.setState({
                    Services: [...removeService],
                    providedService: [...newProvidedService],
                }, () => {
                    this.getCategoryIdIfAllSubCategoriesMatchEdit(item);
                });
            }
            else {
                let getServiceIds = [];
                this.state.Services.forEach((service) => {
                    if (Array.isArray(service)) {
                        service.forEach((servi) => {
                            if (!getServiceIds.includes(servi.id.toString())) {
                                getServiceIds.push(servi.id.toString());
                            }
                        });
                    }
                    else {
                        if (!getServiceIds.includes(service.toString())) {
                            getServiceIds.push(service.toString());
                        }
                    }
                });
                let newService = [];
                this.state.serviceList.forEach((service) => {
                    service.attributes.business_sub_categories.forEach((serviceItem) => {
                        if (serviceItem.category_id == item.id) {
                            newService.push(serviceItem.id.toString());
                        }
                    });
                });
                newService.forEach((serv) => {
                    let selectCheckbox = document.querySelector("#serviceCheckbox" + serv);
                    if (selectCheckbox && !selectCheckbox.checked) {
                        selectCheckbox.click();
                    }
                });
                this.setState({
                    Services: [...getServiceIds, ...newService],
                    providedService: [...this.state.providedService, item.id],
                }, () => this.getCategoryIdIfAllSubCategoriesMatchEdit(item));
            }
        };
        this.formatTime = (timeString) => {
            const [hour, minutes, ampm] = timeString.match(/(\d+):(\d+) (\w+)/).slice(1);
            const formattedHour = hour < 10 && !hour.includes("0") ? `0${hour}` : `${hour}`;
            const formattedTime = `${formattedHour}:${minutes} ${ampm}`;
            return formattedTime;
        };
        this.handleSearchBusinessServices = (searchTerm) => {
            const term = searchTerm.toLowerCase();
            if (term === "") {
                this.setState(Object.assign(Object.assign({}, this.state), { serviceList: this.state.originalServiceList, searchTerm: searchTerm }), () => {
                    this.reapplySelectedStateEdit();
                });
            }
            const results = this.state.originalServiceList
                .map((category) => {
                const { name, business_sub_categories, arabic_name } = category.attributes;
                if (name.toLowerCase().includes(term) ||
                    arabic_name.toLowerCase().includes(term)) {
                    return Object.assign(Object.assign({}, category), { attributes: Object.assign(Object.assign({}, category.attributes), { business_sub_categories: business_sub_categories }) });
                }
                else {
                    const filteredSubCategories = business_sub_categories.filter((subCategory) => subCategory.service_name.toLowerCase().includes(term) ||
                        subCategory.arabic_name.toLowerCase().includes(term));
                    if (filteredSubCategories.length > 0) {
                        return Object.assign(Object.assign({}, category), { attributes: Object.assign(Object.assign({}, category.attributes), { business_sub_categories: filteredSubCategories }) });
                    }
                }
                return null;
            })
                .filter((category) => category !== null);
            this.setState(Object.assign(Object.assign({}, this.state), { searchTerm: searchTerm, serviceList: results }), () => {
                this.reapplySelectedStateEdit();
            });
        };
        this.reapplySelectedStateEdit = () => {
            const selectedServiceIdSetEdit = new Set();
            this.state.Services.forEach((serv) => {
                if (Array.isArray(serv)) {
                    serv.forEach((servi) => {
                        selectedServiceIdSetEdit.add(servi.id);
                    });
                }
                else {
                    selectedServiceIdSetEdit.add(serv);
                }
            });
            const selectedServiceIdsEdit = Array.from(selectedServiceIdSetEdit);
            let allIds = selectedServiceIdsEdit.map((selectedServiceId) => parseInt(selectedServiceId));
            this.state.serviceList.forEach((service) => {
                this.getCategoryIdIfAllSubCategoriesMatchEdit(service);
                service.attributes.business_sub_categories.forEach((serviceItemEdit) => {
                    if (allIds.includes(serviceItemEdit.id)) {
                        const selectCheckbox = document.querySelector("#serviceCheckbox" + serviceItemEdit.id);
                        if (selectCheckbox && !selectCheckbox.checked) {
                            selectCheckbox.click();
                        }
                    }
                });
            });
        };
        this.removeDuplicateFromArrayEdit = () => {
            const editSelectedServiceIdsSet = new Set();
            this.state.Services.forEach((serv) => {
                if (Array.isArray(serv)) {
                    serv.forEach((servi) => {
                        editSelectedServiceIdsSet.add(servi.id);
                    });
                }
                else {
                    editSelectedServiceIdsSet.add(serv);
                }
            });
            const editSelectedServiceIds = Array.from(editSelectedServiceIdsSet);
            this.setState({ Services: editSelectedServiceIds, searchTerm: "", showServiceList: !this.state.showServiceList });
        };
        this.timeDurationFormatEdit = (timeEdit) => {
            const durationEdit = moment.duration(timeEdit);
            const totalMinutesEdit = durationEdit.asMinutes();
            return totalMinutesEdit.toString();
        };
        this.getCategoryIdIfAllSubCategoriesMatchEdit = (business_category_edit) => {
            let category_id_edit = JSON.stringify(business_category_edit.attributes.id);
            const alreadySelectedServicesIdInEdit = this.state.Services.flat().map((item) => item.id ? JSON.stringify(item.id) : item);
            const businessCategoryIdsInEdit = business_category_edit.attributes.business_sub_categories.map((subCat) => JSON.stringify(subCat.id));
            const allIdsPresentInEdit = businessCategoryIdsInEdit.every((id) => alreadySelectedServicesIdInEdit.includes(id));
            if (allIdsPresentInEdit &&
                !this.state.providedService.includes(category_id_edit)) {
                this.setState((prevState) => ({
                    providedService: [...prevState.providedService, category_id_edit],
                }));
            }
            if (!allIdsPresentInEdit &&
                this.state.providedService.includes(category_id_edit)) {
                this.setState((prevState) => ({
                    providedService: prevState.providedService.filter((id) => id !== category_id_edit),
                }));
            }
        };
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        // Customizable Area Start
        this.state = {
            imageChange: false,
            isSelectOpenWeekHoursTo: false,
            isSelectOpenBlockWeekHoursFrom: false,
            isSelectOpenBlockWeekHoursTo: false,
            isSelectOpenWeekEndsFrom: false,
            isSelectOpenWeekHoursFrom: false,
            isSelectOpenWeekEndsTo: false,
            isSelectOpenBlockWeekEndsTo: false,
            isSelectOpenBlockWeekEndsFrom: false,
            backgroundColor: "#f9f9f9",
            color: "#808080",
            empServices: [],
            profile: null,
            profileImage: "",
            token: "",
            logout: false,
            isLoader: false,
            accountOwnerName: "",
            accountOwnerNameError: "",
            accountNumber: "",
            accountNumberError: "",
            openCalenderDialog: false,
            confirmAccountNumber: "",
            confirmAccountNumberError: "",
            openDobCalenderDialog: false,
            ifscCode: "",
            ifscCodeError: "",
            allowTeam: true,
            enablePayAtShop: false,
            enableCardPayment: false,
            showServiceList: false,
            showDeleteModal: false,
            serviceSelectButton: {},
            serviceList: [],
            searchTerm: "",
            searchId: null,
            errorMsg: "",
            serviceId: null,
            serviceValue: "Choose",
            imageModalVisible: false,
            isServiceSelected: false,
            serviceSelectedAll: false,
            openStartCalenderDialog: false,
            openCountryCodeModal: false,
            workingFrom: "",
            workingTo: "",
            businessImages: [],
            businessImageError: "",
            providedService: [],
            selectedTeamSize: null,
            removePhotoEnable: false,
            selectedPhoto: null,
            serviceCategory: [],
            teamSizeData: [],
            workingSlotTo: [],
            weekendSlotTo: [],
            blockWorkingSlotTo: [],
            blockWeekendSlotTo: [],
            workingSlot: [
                "",
                "12:00 AM",
                "12:30 AM",
                "01:00 AM",
                "01:30 AM",
                "02:00 AM",
                "02:30 AM",
                "03:00 AM",
                "03:30 AM",
                "04:00 AM",
                "04:30 AM",
                "05:00 AM",
                "05:30 AM",
                "06:00 AM",
                "06:30 AM",
                "07:00 AM",
                "07:30 AM",
                "08:00 AM",
                "08:30 AM",
                "09:00 AM",
                "09:30 AM",
                "10:00 AM",
                "10:30 AM",
                "11:00 AM",
                "11:30 AM",
                "12:00 PM",
                "12:30 PM",
                "01:00 PM",
                "01:30 PM",
                "02:00 PM",
                "02:30 PM",
                "03:00 PM",
                "03:30 PM",
                "04:00 PM",
                "04:30 PM",
                "05:00 PM",
                "05:30 PM",
                "06:00 PM",
                "06:30 PM",
                "07:00 PM",
                "07:30 PM",
                "08:00 PM",
                "08:30 PM",
                "09:00 PM",
                "09:30 PM",
                "10:00 PM",
                "10:30 PM",
                "11:00 PM",
                "11:30 PM",
            ],
            isWorkingToDisable: true,
            isWeekendToDisable: true,
            isBlockTimeToDisable: true,
            workingHours: [
                {
                    id: 1,
                    isSelected: false,
                    day: "Sunday",
                    type: "weekend",
                },
                {
                    id: 2,
                    isSelected: false,
                    day: "Monday",
                    type: "weekday",
                },
                {
                    id: 3,
                    isSelected: false,
                    day: "Tuesday",
                    type: "weekday",
                },
                {
                    id: 4,
                    isSelected: false,
                    day: "Wednesday",
                    type: "weekday",
                },
                {
                    id: 5,
                    isSelected: false,
                    day: "Thursday",
                    type: "weekday",
                },
                {
                    id: 6,
                    isSelected: false,
                    day: "Friday",
                    type: "weekday",
                },
                {
                    id: 7,
                    isSelected: false,
                    day: "Saturday",
                    type: "weekend",
                },
            ],
            workingDaysData: [
                {
                    id: 1,
                    day: "Sunday",
                    isWeekday: false,
                    isWeekend: false,
                    isDisableWeekend: false,
                    isDisableWeekday: false,
                },
                {
                    id: 2,
                    day: "Monday",
                    isWeekday: false,
                    isWeekend: false,
                    isDisableWeekend: false,
                    isDisableWeekday: false,
                },
                {
                    id: 3,
                    day: "Tuesday",
                    isWeekday: false,
                    isWeekend: false,
                    isDisableWeekend: false,
                    isDisableWeekday: false,
                },
                {
                    id: 4,
                    day: "Wednesday",
                    isWeekday: false,
                    isWeekend: false,
                    isDisableWeekend: false,
                    isDisableWeekday: false,
                },
                {
                    id: 5,
                    day: "Thursday",
                    isWeekday: false,
                    isWeekend: false,
                    isDisableWeekend: false,
                    isDisableWeekday: false,
                },
                {
                    id: 6,
                    day: "Friday",
                    isWeekday: false,
                    isWeekend: false,
                    isDisableWeekend: false,
                    isDisableWeekday: false,
                },
                {
                    id: 7,
                    day: "Saturday",
                    isWeekday: false,
                    isWeekend: false,
                    isDisableWeekend: false,
                    isDisableWeekday: false,
                },
            ],
            Name: "",
            Fname: "",
            FnameError: "",
            NameError: "",
            Lname: "",
            LnameError: "",
            Mobile: "",
            Email: "",
            EmailError: "",
            MobileError: "",
            Dob: "",
            DobError: "",
            GenderError: "",
            AboutMe: "",
            Gender: "",
            StartDate: "",
            Language: "",
            LanguageError: "",
            EndDateError: "",
            StartDateError: "",
            EndDate: "",
            workingHoursError: "",
            BlockTimeFrom: "",
            weekendHoursError: "",
            BlockTimeTo: "",
            workingDaysError: "",
            workingDays: [],
            Services: [],
            ServicesError: "",
            ServicesList: [],
            DateModal: false,
            loading: false,
            DateModalType: "dob",
            imageChanged: false,
            deleteModal: false,
            selectedCountryCode: "951",
            weekendFrom: "",
            weekendTo: "",
            weekdayFrom: "",
            WeekdayTo: "",
            weekdayBlockFrom: "",
            weekdayBlockTo: "",
            weekendBlockFrom: "",
            weekendBlockTo: "",
            weekendDaysError: "",
            weekendDays: [],
            empData: {},
            businessName: "",
            businessNameError: "",
            businessOwner: "",
            businessOwnerError: "",
            businessEmail: "",
            businessEmailError: "",
            website: "",
            websiteError: "",
            crn: "",
            crnError: "",
            contact: "",
            contactError: "",
            bankAccountPlaceholder: "Add bank account",
            accountDetails: [],
            getRoundCorner: 25,
            getSelectionMode: "weekdays",
            serviceError: "",
            bankDetailError: "",
            modalDobError: "",
            modalStartError: "",
            modalEndError: "",
            isTakePhotoModalVisible: false,
            capturedImage: "",
            height: null,
            width: null,
            uri: "",
            // Customizable Area Start
            originalServiceList: [],
        };
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    componentDidMount() {
        const _super = Object.create(null, {
            componentDidMount: { get: () => super.componentDidMount }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.componentDidMount.call(this);
            document.addEventListener('mousedown', this.handleClickOutsideEdit);
            const { editableMemberId } = this.props;
            let isUserLoggedIn = localStorage.getItem("token") || "";
            if (!isUserLoggedIn) {
                this.props.navigation.navigate("Login");
            }
            else {
                this.setState({ searchId: editableMemberId }, () => __awaiter(this, void 0, void 0, function* () {
                    this.getTeamMembers();
                }));
            }
        });
    }
    componentWillUnmount() {
        return __awaiter(this, void 0, void 0, function* () {
            document.removeEventListener('mousedown', this.handleClickOutsideEdit);
        });
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            // this.stopLoading();
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
                if (apiRequestCallId === this.getServicesListCallId) {
                    yield this.handleServiceResponse(responseJson);
                }
                if (apiRequestCallId === this.updateTeamMemberCallId ||
                    apiRequestCallId === this.getTeamMemberDataCallId ||
                    apiRequestCallId === this.deleteTeamMemberCallId) {
                    yield this.handleReceive(apiRequestCallId, responseJson, errorReponse);
                }
            }
            else {
                runEngine.debugLog("GOIT");
            }
        });
    }
}
