// Customizable Area Start
import React from "react";
import { OutlinedInput, InputAdornment, IconButton, TextField, Button, Dialog, DialogContent, Box, } from "@material-ui/core";
import ImageUploading from "react-images-uploading";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import Modal from "@material-ui/core/Modal";
import Checkbox from "@material-ui/core/Checkbox";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import { ArrowUp, ArrowDown, backdrop, } from "../../CustomisableUserProfiles/src/assets";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { search_Bitmap } from "./assets";
import AfterLoginHeader from "../../dashboard/src/AfterLoginHeader.web";
import CountryCodeModal from "../../social-media-account-login/src/CountryCodeModal.web";
import AddTeamMemberWebController from "./AddTeamMemberWebController";
import "../assets/Styles/add-team-member.css";
import "../assets/Styles/search-input-service.scss";
import "../assets/Styles/custom-calendar.scss";
import moment from "moment";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { withTranslation } from "react-i18next";
const Cookies = require("js-cookie");
// Customizable Area End
class AddTeamMember extends AddTeamMemberWebController {
    constructor(props) {
        super(props);
        // Customizable Area Start
        this.handleDateChange = (date) => {
            const formattedDate = moment(date).format("YYYY-MM-DD");
            if (this.state.DateModalType === "startDate") {
                this.setState({ StartDate: formattedDate, StartDateError: "" });
            }
            else {
                this.setState({ EndDate: formattedDate, EndDateError: "" });
            }
        };
        this.handleDateOfBirthChange = (date) => {
            const formattedDate = moment(date).format("YYYY-MM-DD");
            this.setState({ Dob: formattedDate, DobError: "" });
        };
        this.handaleClose = () => {
            this.setState({
                openCalenderDialog: false,
                openStartCalenderDialog: false,
            });
        };
        this.handleOngoing = () => {
            if (this.state.DateModalType === "endDate") {
                this.setState({
                    EndDate: "ongoing",
                    EndDateError: "",
                    openCalenderDialog: false,
                });
            }
        };
        this.handleOpenCalenderDialog = () => {
            const minDate = this.calculateMinDate(this.state.DateModalType, this.state.StartDate, new Date());
            let { t } = this.props;
            return (this.state.openCalenderDialog && (React.createElement(Dialog, { open: this.state.openCalenderDialog, PaperProps: {
                    style: {
                        width: "403px",
                        height: "auto",
                        borderRadius: "24px",
                        boxShadow: "none",
                        background: "#FFFFFF",
                    },
                } },
                React.createElement(DialogContent, null,
                    React.createElement(Box, { mb: 0.5 },
                        React.createElement("div", { style: {
                                marginLeft: "10px",
                            } },
                            React.createElement("div", { className: "add-member-calendar-container" },
                                React.createElement("button", { className: "calendar-close", id: "dialog-close-button", onClick: () => this.setState({ openCalenderDialog: false, EndDate: null }, () => {
                                        this.onDateChange(this.state.EndDate, "end");
                                    }) }, "\u00D7"),
                                React.createElement(Calendar, { minDate: new Date(), value: this.state.EndDate, onChange: this.handleDateChange, className: "date-picker", calendarType: "US", formatMonthYear: (locale, date) => date.toLocaleString(locale, {
                                        month: "short",
                                        year: "numeric",
                                    }) }),
                                React.createElement("div", { style: {
                                        display: "flex",
                                        gap: 10,
                                        marginTop: "35px",
                                    } },
                                    React.createElement("button", { style: {
                                            width: "130px",
                                            height: "52px",
                                            border: "1px solid #2C2C2E",
                                            borderRadius: "28px",
                                            marginLeft: "30px",
                                        }, id: "handleOngoing", disabled: this.state.DateModalType === "startDate", onClick: () => {
                                            this.handleOngoing();
                                        } }, t("Ongoing")),
                                    React.createElement("button", { style: {
                                            width: "130px",
                                            height: "52px",
                                            borderRadius: "28px",
                                            backgroundColor: "#1E5FEA",
                                            color: "#FFFFFF",
                                            border: "none",
                                        }, id: "handleDone", onClick: () => {
                                            this.setState({ openCalenderDialog: false });
                                        } }, t("Done"))))))))));
        };
        this.handleOpenStartCalenderDialog = () => {
            const minDate = this.calculateMinDate(this.state.DateModalType, this.state.StartDate, new Date());
            return (this.state.openStartCalenderDialog && (React.createElement(Dialog, { open: this.state.openStartCalenderDialog, PaperProps: {
                    style: {
                        width: "403px",
                        height: "auto",
                        borderRadius: "24px",
                        boxShadow: "none",
                        background: "#FFFFFF",
                    },
                } },
                React.createElement(DialogContent, null,
                    React.createElement(Box, { mb: 0.5 },
                        React.createElement("div", { style: {
                                marginLeft: "10px",
                            } },
                            React.createElement("div", { className: "add-member-calendar-container" },
                                React.createElement("button", { className: "calendar-close", onClick: () => this.setState({ openStartCalenderDialog: false, StartDate: null }, () => {
                                        this.onDateChange(this.state.StartDate, "start");
                                    }) }, "\u00D7"),
                                React.createElement(Calendar, { value: this.state.StartDate, onChange: this.handleDateChange, className: "start-date-picker", calendarType: "US", formatMonthYear: (locale, date) => date.toLocaleString(locale, {
                                        month: "short",
                                        year: "numeric",
                                    }) }),
                                React.createElement("div", { style: {
                                        display: "flex",
                                        gap: 10,
                                        marginTop: "35px",
                                    } },
                                    React.createElement("button", { style: {
                                            width: "130px",
                                            height: "52px",
                                            border: "1px solid #2C2C2E",
                                            borderRadius: "28px",
                                            marginLeft: "30px",
                                        }, id: "handleOngoing", disabled: this.state.DateModalType === "startDate", onClick: () => {
                                            this.handleOngoing();
                                        } }, this.props.t("Ongoing")),
                                    React.createElement("button", { style: {
                                            width: "130px",
                                            height: "52px",
                                            borderRadius: "28px",
                                            backgroundColor: "#1E5FEA",
                                            color: "#FFFFFF",
                                            border: "none",
                                        }, id: "handleDone", onClick: () => {
                                            this.setState({ openStartCalenderDialog: false });
                                        } }, this.props.t("Done"))))))))));
        };
        this.handleOpenDobCalenderDialog = () => {
            let { t } = this.props;
            return (this.state.openDobCalenderDialog && (React.createElement(Dialog, { open: this.state.openDobCalenderDialog, PaperProps: {
                    style: {
                        width: "403px",
                        height: "auto",
                        borderRadius: "24px",
                        boxShadow: "none",
                        background: "#FFFFFF",
                    },
                } },
                React.createElement(DialogContent, null,
                    React.createElement(Box, { mb: 0.5 },
                        React.createElement("div", { style: {
                                marginLeft: "10px",
                            } },
                            React.createElement("div", { className: "add-member-calendar-container" },
                                React.createElement("button", { className: "calendar-close", id: "dob-close-button", onClick: () => this.setState({ openDobCalenderDialog: false, Dob: null }, () => {
                                        this.onDateChange(this.state.Dob, "dateOfBirth");
                                    }) }, "\u00D7"),
                                React.createElement(Calendar, { maxDate: new Date(new Date().setFullYear(new Date().getFullYear() - 18)), minDate: new Date(1900, 0, 1), className: "dob-dialog", value: this.state.Dob, onChange: this.handleDateOfBirthChange, calendarType: "US", formatMonthYear: (locale, date) => date.toLocaleString(locale, {
                                        month: "short",
                                        year: "numeric",
                                    }) }),
                                React.createElement("div", { style: {
                                        display: "flex",
                                        gap: 10,
                                        marginTop: "35px",
                                    } },
                                    React.createElement("button", { style: {
                                            width: "130px",
                                            height: "52px",
                                            border: "1px solid #2C2C2E",
                                            borderRadius: "28px",
                                            marginLeft: "30px",
                                        }, id: "handleOngoing", disabled: this.state.DateModalType === "startDate", onClick: () => {
                                            this.handleOngoing();
                                        } }, t("Ongoing")),
                                    React.createElement("button", { style: {
                                            width: "130px",
                                            height: "52px",
                                            borderRadius: "28px",
                                            backgroundColor: "#1E5FEA",
                                            color: "#FFFFFF",
                                            border: "none",
                                        }, id: "handleDone", onClick: () => {
                                            this.setState({ openDobCalenderDialog: false });
                                        } }, t("Done"))))))))));
        };
        this.handleOpenCountryCodeModal = () => {
            return (this.state.openCountryCodeModal && (React.createElement(React.Fragment, null,
                React.createElement(CountryCodeModal, { open: this.state.openCountryCodeModal, onSelect: this.handleCountryCodeChange }))));
        };
        this.borderRightAdd = () => {
            let webAppDirection = localStorage.getItem("webAppDirection") ||
                Cookies.get("webAppDirection") ||
                "ltr";
            return webAppDirection === "ltr"
                ? this.state.MobileError
                    ? "1px solid #ff453a"
                    : "1px solid #979797"
                : "";
        };
        this.borderLeftAdd = () => {
            let webAppDirection = localStorage.getItem("webAppDirection") ||
                Cookies.get("webAppDirection") ||
                "ltr";
            return webAppDirection === "rtl"
                ? this.state.MobileError
                    ? "1px solid #ff453a"
                    : "1px solid #979797"
                : "";
        };
    }
    // Customizable Area End
    // Customizable Area Start
    // Customizable Area End
    render() {
        var _a, _b, _c;
        //@ts-ignore
        const { handleAddTeamMember, t } = this.props;
        const { workingHoursError, weekendHoursError } = this.state;
        const borderStyleworkingHours = this.getBorderStyle(workingHoursError);
        const borderStyleweekendHours = this.getBorderStyle(weekendHoursError);
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        let drowpDownClassName = webAppDirection === "ltr" ? "dropdown-image" : "arabic-dropdown-image";
        let regex = /^[a-zA-Z0-9 !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]*$/;
        // Customizable Area Start
        // Customizable Area End
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "add-member-main-container" },
                React.createElement(AfterLoginHeader, { navigation: this.props.navigation }),
                React.createElement("div", { className: "add-member-subheader-container" },
                    React.createElement("div", { className: "add-member-icon" },
                        React.createElement(IconButton, { style: {
                                width: "24px",
                                height: "24px",
                                transform: webAppDirection === "rtl" ? "scaleX(-1)" : "scaleX(1)",
                            } },
                            React.createElement("img", { src: backdrop, alt: "logo", width: "24px", height: "24px", onClick: () => handleAddTeamMember("none", null), id: "addMemberIcon" }))),
                    React.createElement("span", { className: "add-member-subheader-text" }, t("Add Team Member"))),
                React.createElement("div", { className: "add-member-paper-box-container" },
                    React.createElement("div", { className: "add-member-paper-box" },
                        React.createElement("div", { className: "add-member-paper-box-image-div" },
                            React.createElement(ImageUploading, { multiple: true, value: this.state.capturedImage, maxNumber: 1, onChange: this.onImageSelect }, ({ imageList, onImageUpload, onImageUpdate, isDragging, dragProps, }) => (
                            // write your building UI
                            React.createElement("div", { className: "add-member-upload__image-wrapper" }, this.state.capturedImage ? (React.createElement("img", { 
                                //@ts-ignore
                                src: imageList, alt: "", width: "100", 
                                //@ts-ignore
                                onClick: onImageUpdate })) : (React.createElement(AddPhotoAlternateIcon, Object.assign({ style: isDragging ? { color: "red" } : undefined, onClick: onImageUpload }, dragProps))))))),
                        React.createElement("div", { className: "add-member-field-container" },
                            React.createElement("span", { className: "add-member-form-label" }, t("Title")),
                            React.createElement(OutlinedInput, { type: "text", className: "add-member-form-input-style", id: "memberTitle", value: this.state.Name, error: this.state.NameError, onChange: (event) => {
                                    if (/^[a-zA-Z ]*$/.test(event.target.value)) {
                                        this.setState({ NameError: "", Name: event.target.value });
                                    }
                                }, onBlur: this.validateName, inputProps: { min: 0, maxLength: 50 } }),
                            React.createElement("span", { className: "add-member-error-message" }, t(this.renderErrorMessageforProfile(this.state.NameError)))),
                        React.createElement("div", { className: "add-member-field-container-nth-child" },
                            React.createElement("span", { className: "add-member-form-label" }, t("First name")),
                            React.createElement(OutlinedInput, { type: "text", className: "add-member-form-input-style", value: this.state.Fname, id: "memberFname", error: this.state.FnameError, onChange: (event) => {
                                    if (/^[a-zA-Z ]*$/.test(event.target.value)) {
                                        this.setState({ FnameError: "", Fname: event.target.value });
                                    }
                                }, onBlur: this.validateFname, inputProps: { min: 0, maxLength: 25 } }),
                            React.createElement("span", { className: "add-member-error-message" }, t(this.renderErrorMessageforProfile(this.state.FnameError)))),
                        React.createElement("div", { className: "add-member-field-container-nth-child" },
                            React.createElement("span", { className: "add-member-form-label" }, t("Last name")),
                            React.createElement(OutlinedInput, { type: "text", className: "add-member-form-input-style", id: "memberLname", error: this.state.LnameError, value: this.state.Lname, onChange: (event) => {
                                    if (/^[a-zA-Z ]*$/.test(event.target.value)) {
                                        this.setState({ LnameError: "", Lname: event.target.value });
                                    }
                                }, onBlur: this.validateLname, inputProps: { min: 0, maxLength: 25 } }),
                            React.createElement("span", { className: "add-member-error-message" }, t(this.renderErrorMessageforProfile(this.state.LnameError)))),
                        React.createElement("div", { className: "add-member-field-container-nth-child" },
                            React.createElement("span", { className: "add-member-form-label" }, t("Email Address")),
                            React.createElement(OutlinedInput, { id: "memberEmail", value: this.state.Email, className: "add-member-form-input-style", error: this.state.EmailError, type: "text", onChange: (event) => {
                                    if (regex.test(event.target.value)) {
                                        this.setState({ EmailError: "", Email: event.target.value });
                                    }
                                }, onBlur: this.validateEmail }),
                            React.createElement("span", { className: "add-member-error-message" }, t(this.renderErrorMessageforProfile(this.state.EmailError)))),
                        React.createElement("div", { className: "add-member-field-container-nth-child" },
                            React.createElement("span", { className: "add-member-form-label" }, t("Mobile number")),
                            React.createElement(OutlinedInput, { id: "memberMobile", type: "text", error: this.state.MobileError, className: "add-member-form-input-style", value: this.state.Mobile, onChange: (event) => {
                                    if (/^$|^[\d]{0,14}$/.test(event.target.value)) {
                                        this.setState({ MobileError: "", Mobile: event.target.value });
                                    }
                                }, onBlur: this.validateMobile, startAdornment: React.createElement(InputAdornment, { position: webAppDirection === "ltr" ? "start" : "end", onClick: () => this.handleCountryCodeChange() },
                                    React.createElement(IconButton, { style: {
                                            width: 66,
                                            height: 54,
                                            borderRight: this.borderRightAdd(),
                                            borderLeft: this.borderLeftAdd(),
                                            cursor: "pointer",
                                            borderRadius: "0%",
                                            justifyContent: "center",
                                        } },
                                        React.createElement("span", { className: "country-code-text-style", style: {
                                                color: this.state.MobileError
                                                    ? "#ff453a"
                                                    : "#2c2c2e",
                                            } }, this.state.selectedCountryCode))) }),
                            React.createElement("span", { className: "add-member-error-message" }, t(this.renderErrorMessageforProfile(this.state.MobileError)))),
                        React.createElement("div", { className: "add-member-field-container-nth-child" },
                            React.createElement("span", { className: "add-member-form-label" }, t("Gender")),
                            React.createElement("div", { className: "add-member-form-custom-select large-input form-custom-select" },
                                React.createElement("select", { name: "Gender", className: "add-member-form-input-style", value: this.state.Gender, id: "memberGender", onChange: (event) => {
                                        this.setState({
                                            GenderError: "",
                                            Gender: event.target.value,
                                        });
                                    } },
                                    React.createElement("option", { value: "", disabled: true, selected: true, hidden: true, style: { color: "grey" } }, t("Select")),
                                    React.createElement("option", { value: "male" }, t("Male")),
                                    React.createElement("option", { value: "female" }, t("Female"))),
                                React.createElement("img", { src: ArrowDown, alt: "Custom Icon", className: drowpDownClassName }))),
                        React.createElement("div", { className: "add-member-field-container-nth-child" },
                            React.createElement("span", { className: "add-member-form-label" }, t("Date of birth")),
                            React.createElement(OutlinedInput, { id: "dateOfBirth", type: "text", className: "add-member-form-input-style", onClick: () => {
                                    this.setState({
                                        Dob: new Date(),
                                        openDobCalenderDialog: true,
                                        DateModalType: "dateOfBirth",
                                    });
                                }, onBlur: () => {
                                    this.onDateChange(this.state.Dob, "dateOfBirth");
                                }, placeholder: t("Select date"), value: this.state.Dob, style: { fontFamily: "Poppins-Medium, sans-serif" }, endAdornment: React.createElement(InputAdornment, { position: "end" },
                                    React.createElement(IconButton, { id: "downArrowIcon", style: { fontWeight: 10 }, "aria-label": "toggle password visibility", edge: "end" },
                                        React.createElement("img", { src: ArrowDown, alt: "type", width: "25px", height: "26px", className: drowpDownClassName }))) })),
                        React.createElement("div", { className: "add-member-field-container-nth-child" },
                            React.createElement("span", { className: "add-member-form-label" }, t("Preferred language")),
                            React.createElement("div", { className: "add-member-form-custom-select large-input form-custom-select" },
                                React.createElement("select", { id: "memberLanguage", className: "add-member-form-input-style", value: this.state.Language, name: "Language", onChange: (event) => {
                                        this.setState({
                                            LanguageError: "",
                                            Language: event.target.value,
                                        });
                                    } },
                                    React.createElement("option", { selected: true, disabled: true, value: "", hidden: true, style: { color: "grey" } }, t("Select")),
                                    React.createElement("option", { value: "english" }, t("English")),
                                    React.createElement("option", { value: "arabic" }, t("Arabic"))),
                                React.createElement("img", { src: ArrowDown, alt: "Custom Icon", className: drowpDownClassName }))),
                        React.createElement("div", { className: "add-member-field-container-nth-child" },
                            React.createElement("span", { className: "add-member-form-label" }, t("About me")),
                            React.createElement(OutlinedInput, { style: {
                                    height: "auto",
                                    padding: "10px",
                                }, value: this.state.AboutMe, className: "add-member-form-input-style", id: "memberAbout", multiline: true, name: "AboutMe", type: "text", rows: 6, onChange: (event) => {
                                    this.setState({ AboutMe: event.target.value });
                                }, inputProps: { min: 0, maxLength: 100 } }))),
                    React.createElement("div", { className: "add-member-paper-box", style: { backgroundColor: "transparent" } },
                        React.createElement("div", { className: "add-member-right-subheader-container" },
                            React.createElement("span", { className: "add-member-subheader-text" }, t("Employment"))),
                        React.createElement("div", { className: "add-member-Employment-card-container" },
                            React.createElement("div", { className: "add-member-field-container-nth-child" },
                                React.createElement("span", { className: "add-member-card-form-label" }, t("Start date")),
                                React.createElement(OutlinedInput, { id: "calenderstartTime", type: "text", onClick: () => {
                                        this.setState({
                                            StartDate: new Date(),
                                            openStartCalenderDialog: true,
                                            DateModalType: "startDate",
                                        });
                                    }, onBlur: () => {
                                        this.onDateChange(this.state.StartDate, "start");
                                    }, placeholder: t("Select date"), value: this.state.StartDate, style: { fontFamily: "Poppins-Medium, sans-serif" }, endAdornment: React.createElement(InputAdornment, { position: "end" },
                                        React.createElement(IconButton, { id: "downArrowIcon", style: { fontWeight: 10 }, "aria-label": "toggle password visibility", edge: "end" },
                                            React.createElement("img", { src: ArrowDown, alt: "type", width: "25px", height: "26px", className: drowpDownClassName }))) })),
                            React.createElement("div", { className: "add-member-field-container-nth-child" },
                                React.createElement("span", { className: "add-member-card-form-label" }, t("End date")),
                                React.createElement(OutlinedInput, { id: "calender-endtime", type: "text", value: t(this.state.EndDate), placeholder: t("Select date"), onClick: () => {
                                        this.setState({
                                            EndDate: new Date(),
                                            openCalenderDialog: true,
                                            DateModalType: "endDate",
                                        });
                                    }, onBlur: () => {
                                        this.onDateChange(this.state.EndDate, "end");
                                    }, style: { fontFamily: "Poppins-Medium, sans-serif" }, endAdornment: React.createElement(InputAdornment, { position: "end" },
                                        React.createElement(IconButton, { "aria-label": "toggle password visibility", edge: "end" },
                                            React.createElement("img", { src: ArrowDown, alt: "type", width: "25px", height: "26px", className: drowpDownClassName }))) }))),
                        React.createElement("div", { className: "add-member-right-header-container-nth-child" },
                            React.createElement("span", { className: "add-member-subheader-text" }, t("Working Days & Time"))),
                        React.createElement("div", { ref: this.selectContainer, className: "add-member-workingday-card-container" },
                            React.createElement("div", { className: "add-member-workingday-card-content" },
                                React.createElement(ToggleButtonGroup, { value: "value", "aria-label": "workingdays" },
                                    React.createElement(ToggleButton, { id: "toggleButton", style: {
                                            color: this.getTextColor(this.state.getSelectionMode, "weekdays"),
                                            backgroundColor: this.getBackGroundColor(this.state.getSelectionMode, "weekdays"),
                                        }, className: "add-member-myCustomButton", value: "Weekdays", onClick: () => {
                                            this.updatedSwitchData("weekdays");
                                        } }, t("Weekdays")),
                                    React.createElement(ToggleButton, { className: "add-member-myCustomButton", style: {
                                            marginLeft: 10,
                                            color: this.getTextColor(this.state.getSelectionMode, "weekends"),
                                            backgroundColor: this.getBackGroundColor(this.state.getSelectionMode, "weekends"),
                                        }, value: "Weekends", onClick: () => {
                                            this.updatedSwitchData("weekends");
                                        } }, t("Weekends")))),
                            this.state.getSelectionMode === "weekdays" ? (React.createElement("div", { className: "input-container-item-time add-member-input-container-item-time" },
                                React.createElement("div", { className: "add-member-hours-form hours-form" },
                                    React.createElement("div", { style: {
                                            position: "absolute",
                                            marginBottom: 50,
                                        } },
                                        React.createElement("span", { className: "profile-form-sub-header add-member-profile-form-sub-header" }, t("From"))),
                                    React.createElement("div", { className: "add-member-form-custom-select form-custom-select " },
                                        React.createElement("select", { id: "SelectWeekHour", onChange: (event) => {
                                                this.onWorkingFromSelection(event.target.value);
                                            }, onClick: () => {
                                                this.handleSelectToggle("isSelectOpenWeekHoursFrom");
                                            }, style: {
                                                border: borderStyleworkingHours,
                                                borderRadius: 8,
                                                direction: "inherit",
                                            }, value: this.state.workingFrom }, this.state.workingSlot.map((option, workingFromIndex) => {
                                            let [time, amPm] = this.showTimeInUppercase(option).split(" ");
                                            return (React.createElement("option", { key: workingFromIndex, disabled: this.optionDisable(option), id: workingFromIndex, value: option },
                                                time,
                                                " ",
                                                t(amPm)));
                                        })),
                                        React.createElement("img", { src: this.state.isSelectOpenWeekHoursFrom
                                                ? ArrowUp
                                                : ArrowDown, alt: "Custom Icon", className: drowpDownClassName }))),
                                React.createElement("div", { className: "add-member-hours-to hours-to" },
                                    React.createElement("div", { style: {
                                            position: "absolute",
                                            marginBottom: 50,
                                        } },
                                        React.createElement("span", { className: "add-member-profile-form-sub-header profile-form-sub-header" }, t("To"))),
                                    React.createElement("div", { className: "add-member-form-custom-select form-custom-select" },
                                        React.createElement("select", { id: "mySelectTo", disabled: this.state.isWorkingToDisable, value: this.state.workingTo, onChange: (event) => {
                                                this.setState({
                                                    workingTo: event.target.value,
                                                    workingHoursError: "",
                                                });
                                            }, onClick: () => {
                                                this.handleSelectToggle("isSelectOpenWeekHoursTo");
                                            }, style: {
                                                border: borderStyleworkingHours,
                                                borderRadius: 8,
                                                direction: "inherit",
                                            } }, this.state.workingSlotTo.map((option, toIndex) => {
                                            let [time, amPm] = this.showTimeInUppercase(option).split(" ");
                                            return (React.createElement("option", { key: toIndex, disabled: this.optionDisable(option), id: toIndex, value: option },
                                                time,
                                                " ",
                                                t(amPm)));
                                        })),
                                        React.createElement("img", { src: this.state.isSelectOpenWeekHoursTo
                                                ? ArrowUp
                                                : ArrowDown, alt: "Custom Icon", className: drowpDownClassName }))))) : (React.createElement("div", { className: "input-container-item-time add-member-input-container-item-time" },
                                React.createElement("div", { className: "add-member-hours-form hours-form" },
                                    React.createElement("div", { style: {
                                            position: "absolute",
                                            marginBottom: 50,
                                        } },
                                        React.createElement("span", { className: "profile-form-sub-header add-member-profile-form-sub-header" }, t("From"))),
                                    React.createElement("div", { className: "add-member-form-custom-select form-custom-select" },
                                        React.createElement("select", { id: "mySelectFromWeekend", value: this.state.weekendFrom, onChange: (event) => {
                                                this.onWeekendFromSelection(event.target.value);
                                            }, onClick: () => {
                                                this.handleSelectToggle("isSelectOpenWeekEndsFrom");
                                            }, style: {
                                                border: borderStyleweekendHours,
                                                borderRadius: 8,
                                                direction: "inherit",
                                            } }, this.state.workingSlot.map((option, fromIndex) => {
                                            let [time, amPm] = this.showTimeInUppercase(option).split(" ");
                                            return (React.createElement("option", { key: fromIndex, disabled: this.optionDisable(option), id: fromIndex, value: option },
                                                time,
                                                " ",
                                                t(amPm)));
                                        })),
                                        React.createElement("img", { src: this.state.isSelectOpenWeekEndsFrom
                                                ? ArrowUp
                                                : ArrowDown, alt: "Custom Icon", className: drowpDownClassName }))),
                                React.createElement("div", { className: "add-member-hours-to hours-to" },
                                    React.createElement("div", { style: {
                                            position: "absolute",
                                            marginBottom: 50,
                                        } },
                                        React.createElement("span", { className: "add-member-profile-form-sub-header profile-form-sub-header" }, t("To"))),
                                    React.createElement("div", { className: "add-member-form-custom-select form-custom-select" },
                                        React.createElement("select", { id: "mySelectToWeekend", disabled: this.state.isWeekendToDisable, value: this.state.weekendTo, onChange: (event) => {
                                                this.setState({
                                                    weekendTo: event.target.value,
                                                    weekendHoursError: "",
                                                });
                                            }, onClick: () => {
                                                this.handleSelectToggle("isSelectOpenWeekEndsTo");
                                            }, style: {
                                                border: borderStyleweekendHours,
                                                borderRadius: 8,
                                                direction: "inherit",
                                            } }, this.state.weekendSlotTo.map((option, index) => {
                                            let [time, amPm] = this.showTimeInUppercase(option).split(" ");
                                            return (React.createElement("option", { key: index, disabled: this.optionDisable(option), id: index, value: option },
                                                time,
                                                " ",
                                                t(amPm)));
                                        })),
                                        React.createElement("img", { src: this.state.isSelectOpenWeekEndsTo
                                                ? ArrowUp
                                                : ArrowDown, alt: "Custom Icon", className: drowpDownClassName }))))),
                            React.createElement("div", { className: "add-member-input-container-item-time" },
                                this.state.workingHoursError && (React.createElement("div", { style: {
                                        position: "relative",
                                        marginLeft: 60,
                                        marginBottom: 10,
                                    } },
                                    React.createElement("span", { className: "add-member-profile-error-message-text" }, t(this.state.workingHoursError)))),
                                this.state.weekendHoursError && (React.createElement("div", { style: {
                                        position: "relative",
                                        marginLeft: 60,
                                        marginBottom: 10,
                                    } },
                                    React.createElement("span", { className: "add-member-profile-error-message-text" }, t(this.state.weekendHoursError))))),
                            React.createElement("div", { className: "add-member-right-header-container-nth-child subHeader", style: { marginTop: 0, marginLeft: 0 } },
                                React.createElement("span", { className: "add-member-card-form-label" }, t("Block time"))),
                            this.state.getSelectionMode === "weekdays" ? (React.createElement("div", { className: "input-container-item-time add-member-input-container-item-time" },
                                React.createElement("div", { className: "add-member-hours-form hours-form", style: { marginTop: 20 } },
                                    React.createElement("div", { style: {
                                            position: "absolute",
                                            marginBottom: 50,
                                        } },
                                        React.createElement("span", { className: "profile-form-sub-header add-member-profile-form-sub-header" }, t("From"))),
                                    React.createElement("div", { className: "add-member-form-custom-select form-custom-select " },
                                        React.createElement("select", { id: "SelectWeekHours", onChange: (event) => {
                                                this.onBlockTimeFromSelection(event.target.value);
                                            }, onClick: () => {
                                                this.handleSelectToggle("isSelectOpenBlockWeekHoursFrom");
                                            }, style: {
                                                border: borderStyleworkingHours,
                                                borderRadius: 8,
                                                direction: "inherit",
                                            }, value: this.state.BlockTimeFrom }, this.state.workingSlot.map((option, myIndex) => {
                                            let [time, amPm] = this.showTimeInUppercase(option).split(" ");
                                            return (React.createElement("option", { key: myIndex, disabled: this.optionDisable(option), id: myIndex, value: option },
                                                time,
                                                " ",
                                                t(amPm)));
                                        })),
                                        React.createElement("img", { src: this.state.isSelectOpenBlockWeekHoursFrom
                                                ? ArrowUp
                                                : ArrowDown, alt: "Custom Icon", className: drowpDownClassName }))),
                                React.createElement("div", { className: "add-member-hours-to hours-to", style: { marginTop: 20 } },
                                    React.createElement("div", { style: {
                                            position: "absolute",
                                            marginBottom: 50,
                                        } },
                                        React.createElement("span", { className: "add-member-profile-form-sub-header profile-form-sub-header" }, t("To"))),
                                    React.createElement("div", { className: "add-member-form-custom-select form-custom-select" },
                                        React.createElement("select", { id: "selectWeekHoursTo", disabled: this.state.isWorkingToDisable, value: this.state.BlockTimeTo, onChange: (event) => {
                                                this.setState({
                                                    BlockTimeTo: event.target.value,
                                                    workingHoursError: "",
                                                });
                                            }, onClick: () => {
                                                this.handleSelectToggle("isSelectOpenBlockWeekHoursTo");
                                            }, style: {
                                                border: borderStyleworkingHours,
                                                borderRadius: 8,
                                                direction: "inherit",
                                            } }, this.state.workingSlotBlockTo.map((option, myIndexTo) => {
                                            let [time, amPm] = this.showTimeInUppercase(option).split(" ");
                                            return (React.createElement("option", { key: myIndexTo, disabled: this.optionDisable(option), id: JSON.stringify(myIndexTo), value: option },
                                                time,
                                                " ",
                                                t(amPm)));
                                        })),
                                        React.createElement("img", { src: this.state.isSelectOpenBlockWeekHoursTo
                                                ? ArrowUp
                                                : ArrowDown, alt: "Custom Icon", className: drowpDownClassName }))))) : (React.createElement("div", { className: "input-container-item-time add-member-input-container-item-time" },
                                React.createElement("div", { className: "add-member-hours-form hours-form" },
                                    React.createElement("div", { style: {
                                            position: "absolute",
                                            marginBottom: 50,
                                        } },
                                        React.createElement("span", { className: "profile-form-sub-header add-member-profile-form-sub-header" }, t("From"))),
                                    React.createElement("div", { className: "add-member-form-custom-select form-custom-select " },
                                        React.createElement("select", { id: "mySelectWeekendBlockFrom", value: this.state.weekendBlockFrom, onChange: (event) => {
                                                this.onBlockWeekEndTimeFromSelection(event.target.value);
                                            }, onClick: () => {
                                                this.handleSelectToggle("isSelectOpenBlockWeekEndsFrom");
                                            }, style: {
                                                border: borderStyleweekendHours,
                                                borderRadius: 8,
                                                direction: "inherit",
                                            } }, this.state.workingSlot.map((option, blockIndex) => {
                                            let [time, amPm] = this.showTimeInUppercase(option).split(" ");
                                            return (React.createElement("option", { key: blockIndex, disabled: this.optionDisable(option), id: blockIndex, value: option },
                                                time,
                                                " ",
                                                t(amPm)));
                                        })),
                                        React.createElement("img", { src: this.state.isSelectOpenBlockWeekEndsFrom
                                                ? ArrowUp
                                                : ArrowDown, alt: "Custom Icon", className: drowpDownClassName }))),
                                React.createElement("div", { className: "add-member-hours-to hours-to" },
                                    React.createElement("div", { style: {
                                            position: "absolute",
                                            marginBottom: 50,
                                        } },
                                        React.createElement("span", { className: "add-member-profile-form-sub-header profile-form-sub-header" }, t("To"))),
                                    React.createElement("div", { className: "add-member-form-custom-select form-custom-select" },
                                        React.createElement("select", { id: "mySelectWeekendBlockTo", disabled: this.state.isWeekendToDisable, value: this.state.weekendBlockTo, onChange: (event) => {
                                                this.setState({
                                                    weekendBlockTo: event.target.value,
                                                    weekendHoursError: "",
                                                });
                                            }, onClick: () => {
                                                this.handleSelectToggle("isSelectOpenBlockWeekEndsTo");
                                            }, style: {
                                                border: borderStyleweekendHours,
                                                borderRadius: 8,
                                                direction: "inherit",
                                            } }, this.state.weekendSlotBlockTo.map((option, weekendBlockIndex) => {
                                            let [time, amPm] = this.showTimeInUppercase(option).split(" ");
                                            return (React.createElement("option", { key: weekendBlockIndex, disabled: this.optionDisable(option), id: JSON.stringify(weekendBlockIndex), value: option },
                                                time,
                                                " ",
                                                t(amPm)));
                                        })),
                                        React.createElement("img", { src: this.state.isSelectOpenBlockWeekEndsTo
                                                ? ArrowUp
                                                : ArrowDown, alt: "Custom Icon", className: drowpDownClassName }))))),
                            React.createElement("div", { className: "add-member-input-container-item-time" },
                                this.state.workingHoursError && (React.createElement("div", { style: {
                                        position: "relative",
                                        marginLeft: 60,
                                        marginBottom: 10,
                                    } },
                                    React.createElement("span", { className: "add-member-profile-error-message-text" }, this.state.workingHoursError))),
                                this.state.weekendHoursError && (React.createElement("div", { style: {
                                        position: "relative",
                                        marginLeft: 60,
                                        marginBottom: 10,
                                    } },
                                    React.createElement("span", { className: "add-member-profile-error-message-text" }, this.state.weekendHoursError)))),
                            React.createElement("div", { className: "add-member-right-header-container-nth-child subHeader", style: { marginLeft: 0, marginTop: 0 } },
                                React.createElement("span", { className: "add-member-card-form-label" }, t("Working Days"))),
                            React.createElement("div", { className: "add-member-weekDays-map-container", style: { marginLeft: 0, marginTop: 0 } }, this.state.workingDaysData.map((item) => {
                                return (React.createElement(Button, { className: "add-member-employess-weekdays-button", id: "workingDays", key: item.id, onClick: () => {
                                        this.handleWorkingHoursSelection(item.id);
                                    }, style: {
                                        padding: 0,
                                        border: 0,
                                        marginRight: 10,
                                        borderRadius: "27px",
                                        width: "144px",
                                        height: "54px",
                                        fontSize: "18px",
                                        marginBottom: 10,
                                        background: "#1E5FEA",
                                        backgroundColor: this.handleBackgroundColor(item),
                                        color: this.handleColor(item),
                                    }, color: "primary" },
                                    React.createElement("span", { className: "add-member-fontfamily-profile" }, t(item.day))));
                            }))),
                        React.createElement("div", { className: "add-member-right-header-container-nth-child" },
                            React.createElement("span", { className: "add-member-subheader-text" }, t("Services"))),
                        React.createElement("div", { className: "add-member-right-header-container-nth-child HeaderNote" },
                            React.createElement("span", null, t("Add the services this team member can offer."))),
                        React.createElement("div", { className: "add-member-right-header-container-services" },
                            React.createElement(OutlinedInput, { type: "text", id: "services", className: "add-member-allService-form-input-style", onClick: () => {
                                    this.setState({
                                        showServiceList: !this.state.showServiceList,
                                    }, () => {
                                        this.getServicesListApi();
                                    });
                                }, value: this.state.Services.length == 0
                                    ? t("Choose Services")
                                    : `${t("All services")}(${this.state.Services.length})` })),
                        React.createElement("div", { className: "add-member-buttons-container" },
                            React.createElement(Button, { id: "cancelButton", className: "add-member-cancel-button", onClick: () => handleAddTeamMember("none", null) }, t("Cancel")),
                            React.createElement(Button, { className: "add-member-save-button", id: "saveButton", disabled: this.checkSaveDisable(), onClick: () => this.handleSaveMember() }, t("Save"))))),
                this.state.showServiceList && (React.createElement(React.Fragment, null,
                    React.createElement("div", { style: {
                            position: "fixed",
                            content: " ",
                            width: "100%",
                            height: "100vh",
                            background: "rgba(0,0,0, 0.4)",
                            zIndex: 0,
                            top: "0",
                            left: "0",
                        } }, "\u00A0"),
                    React.createElement(Modal, { id: "serviceModalClose", open: this.state.showServiceList, className: webAppDirection === "ltr"
                            ? "add-member-service-modal"
                            : "arabic-add-member-service-modal", onClose: () => this.setState({
                            showServiceList: !this.state.showServiceList,
                        }) },
                        React.createElement("div", { className: webAppDirection === "ltr"
                                ? "add-member-service-modal-container"
                                : "arabic-add-member-service-modal-container" },
                            React.createElement("div", { className: "add-member-service-category" },
                                React.createElement("span", { className: "service-header" }, t("Services")),
                                React.createElement("span", { className: "service-category" }, (_c = (_b = (_a = this.state.serviceList[0]) === null || _a === void 0 ? void 0 : _a.attributes) === null || _b === void 0 ? void 0 : _b.business_provided) === null || _c === void 0 ? void 0 : _c.display_name)),
                            React.createElement("div", { className: "add-member-search-service-input" },
                                React.createElement(TextField, { value: this.state.searchTerm, id: "outlined-basic add-team-member-search", "data-testid": "add-team-member-search", onChange: (event) => this.handleSearchBusinessServicesAdd(event.target.value), InputProps: {
                                        startAdornment: (React.createElement(InputAdornment, { position: "start" },
                                            React.createElement("img", { className: "search-icon", src: search_Bitmap, alt: "" }))),
                                    }, placeholder: t("Search for Services"), variant: "outlined" })),
                            this.state.serviceList.map((service) => (React.createElement("div", { key: service.attributes.name },
                                React.createElement("div", { className: "add-member-service-header" },
                                    React.createElement("h4", null, service.attributes.display_name),
                                    React.createElement(Button, { id: "serviceButton" + service.id, style: { color: "#ffffff" }, onClick: () => this.selectCall(service) }, this.state.providedService.includes(service.id)
                                        ? t("Deselect All")
                                        : t("Select All"))),
                                service.attributes.business_sub_categories.map((serviceItem) => (React.createElement("div", { key: serviceItem.service_name, className: "add-member-manage-services-item-container" },
                                    React.createElement("div", { className: "add-member-user-details" },
                                        React.createElement("span", { className: "add-member-service-sub-category" },
                                            this.state.Services.includes(serviceItem.id),
                                            serviceItem.display_name),
                                        React.createElement("span", { className: "add-member-service-sub-category-details" },
                                            this.timeDurationFormatAdd(serviceItem.duration),
                                            " ",
                                            t("min"),
                                            " - OMR ",
                                            serviceItem.service_cost)),
                                    React.createElement("span", { className: "caret-right" },
                                        React.createElement(Checkbox, { icon: React.createElement(CircleUnchecked, null), checkedIcon: React.createElement(CircleCheckedFilled, null), id: "serviceCheckbox" + serviceItem.id, key: serviceItem.service_name, onChange: (event) => this.onInputChange(event, service), name: serviceItem.service_name, value: serviceItem.id, inputProps: { "aria-label": "A" } })))))))),
                            React.createElement("div", { className: "add-member-buttons-container" },
                                React.createElement(Button, { id: "serviceCancelButton", className: "add-member-cancel-button responsiveBtn", onClick: this.removeDuplicateFromArrayAdd }, t("Cancel")),
                                React.createElement(Button, { id: "serviceSaveButton", className: "add-member-save-button responsiveBtn .rgtBtn", onClick: this.removeDuplicateFromArrayAdd }, t("Save"))))))),
                this.handleOpenCalenderDialog(),
                this.handleOpenStartCalenderDialog(),
                this.handleOpenDobCalenderDialog(),
                this.handleOpenCountryCodeModal())));
    }
}
//@ts-ignore
export default withTranslation()(AddTeamMember);
